import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Container, Text, Link } from "theme-ui"

const ScheduleACallPage = () => {
  var locZohoBooking =
    "https://renitajoseph-eutact.zohobookings.com/#/customer/eutactinc"
  useEffect(() => {
    window.location = locZohoBooking
  })
  return (
    <Layout scrollOnNavLink={false}>
      <SEO title="Schedule a call" />
      <Container
        sx={{
          height: "90vh",
          pt: "6",
        }}>
        <Heading
          as="h3"
          py={5}
          variant="text.section_headline"
          sx={{
            textAlign: "center",
          }}>
          Schedule a call
        </Heading>
        <Text variant="text.body">
          If your browser doesn't redirect you automatically, please click{" "}
          <Link
            href={locZohoBooking}
            rel="external"
            target="_blank"
            aria-label="Schedule a meeting via Zoho Bookings">
            here to schedule a meeting via Zoho Bookings
          </Link>
        </Text>
      </Container>
    </Layout>
  )
}

export default ScheduleACallPage
